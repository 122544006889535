<template>
  <div class="view-container">
    <div class="card">
      <div class="card-header card-header-divider">
        <div class="card-title">Связь с сайтом</div>
      </div>
      <div class="card-body">
        <relations-form @changeEntity="changeEntity" />
        <relations-table :entityId="entityId" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import RelationsForm from "@/feature/relations/RelationsForm.vue";
import RelationsTable from "@/feature/relations/RelationsTable.vue";
export default {
  components: { RelationsForm, RelationsTable },
  name: "relations-page",
  setup() {
    const entityId = ref(null);

    const changeEntity = (id) => {
      entityId.value = id;
    };

    return {
      entityId,
      changeEntity,
    };
  },
};
</script>

<style>
</style>